// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-sciplay-core-values-js": () => import("./../../../src/pages/sciplay-core-values.js" /* webpackChunkName: "component---src-pages-sciplay-core-values-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-game-page-template-js": () => import("./../../../src/templates/GamePageTemplate.js" /* webpackChunkName: "component---src-templates-game-page-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

